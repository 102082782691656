// Generated by Framer (1be12e0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {wZJZV6gdB: {hover: true}};

const serializationHash = "framer-bvh0a"

const variantClassNames = {wZJZV6gdB: "framer-v-1li631g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, OuzOmTBRd: link ?? props.OuzOmTBRd} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, OuzOmTBRd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "wZJZV6gdB", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={OuzOmTBRd}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1li631g", className, classNames)} framer-1xepurg`} data-framer-name={"Light"} layoutDependency={layoutDependency} layoutId={"wZJZV6gdB"} ref={ref ?? ref1} style={{mask: "none", WebkitMask: "none", ...style}} variants={{"wZJZV6gdB-hover": {mask: "linear-gradient(59deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%) add", WebkitMask: "linear-gradient(59deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%) add"}}} {...addPropertyOverrides({"wZJZV6gdB-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-ex0d93"} data-framer-name={"M-icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"yseUlkNxl"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 67 18\"><path d=\"M 0.01 17.992 L 9.995 17.992 L 17.011 0.959 L 17.011 17.992 L 36.602 17.992 L 43.617 0.959 L 43.617 17.992 L 63.208 17.992 L 66.905 9.015 L 56.92 9.015 L 53.602 17.07 L 53.602 0.038 L 34.011 0.038 L 26.996 17.07 L 26.996 0.038 L 7.405 0.038 Z\" fill=\"var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(23, 23, 23)) /* {&quot;name&quot;:&quot;Black 300&quot;} */\"></path></svg>"} svgContentId={10552708136} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bvh0a.framer-1xepurg, .framer-bvh0a .framer-1xepurg { display: block; }", ".framer-bvh0a.framer-1li631g { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 50px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 70px; }", ".framer-bvh0a .framer-ex0d93 { flex: none; height: 18px; position: relative; width: 67px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bvh0a.framer-1li631g { gap: 0px; } .framer-bvh0a.framer-1li631g > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-bvh0a.framer-1li631g > :first-child { margin-left: 0px; } .framer-bvh0a.framer-1li631g > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 70
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"cQJNQQYBB":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"OuzOmTBRd":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerF0DbH33To: React.ComponentType<Props> = withCSS(Component, css, "framer-bvh0a") as typeof Component;
export default FramerF0DbH33To;

FramerF0DbH33To.displayName = "Menu/M";

FramerF0DbH33To.defaultProps = {height: 50, width: 70};

addPropertyControls(FramerF0DbH33To, {OuzOmTBRd: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerF0DbH33To, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})